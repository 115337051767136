import { Inject } from 'inversify-props'
import { AxiosRequestConfig } from "axios";
import { Page, Pageable } from "@/models";
import { LogsDePedidos } from "@/models/venda/LogsDePedidos";
import { PedidoServiceAdapter } from './adapter';

export class FindLogsDePedidosUseCase {
	@Inject('PedidoServiceAdapter')
	private pedidoServiceAdapter!: PedidoServiceAdapter

	findLogsByIdDoPedido = async (id: string, page: Pageable, config?: AxiosRequestConfig): Promise<Page<LogsDePedidos>> =>
		await this.pedidoServiceAdapter.findLogsByIdDoPedido(id, page, config)
}